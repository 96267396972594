import firebase from 'firebase/app'
import store from '@/store'

export default class GetUserCollection {
  constructor() {
    this.dic = {}
  }

  async fetch() {
    const uid = store.getters['userBasic/uid']

    try {
      await firebase.firestore().collection('user').doc(uid)
        .get()
        .then(doc => {
          const data = doc.data()
          if (data) { this.dic = data }
        })

      const response = {
        status: 'success',
        data: this.dic,
      }
      return response
    } catch (error) {
      const response = {
        status: 'error',
        msg: error,
      }
      return response
    }
  }
}
