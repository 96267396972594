<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <h2 class="text-center text-primary">
          タッチファミリーケア
        </h2>
        <div class="text-center text-primary mb-1">
          ユーザ登録
        </div>
        <b-card-text class="mb-2">
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body mt-1 mb-2">
              <div class="d-flex">
                <div class="">
                  <b-avatar
                    size="40"
                    src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
                    variant="light-primary"
                  />
                </div>
                <div class="ml-1">
                  このプラットフォームを利用するためには<b>アカウントの作成</b>が必要です。
                </div>
              </div>
            </div>
          </b-alert>
        </b-card-text>
        <b-card-text class="mb-2">
          <div class="">
            メールアドレスを入力して｢送信する｣ボタンを押して下さい。
          </div>
        </b-card-text>
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
          >
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="mailaddress"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              v-if="status == 1"
              variant="primary"
              block
              :disabled="invalid"
              style="margin: 10% 0;"
              @click="send"
            >
              送信する
            </b-button>
            <div
              v-if="status == 2"
              class="text-center mt-2"
            >
              <b-spinner
                style="width: 3rem; height: 3rem;"
                class="spinner_"
                variant="primary"
              />
            </div>
          </b-form>
        </validation-observer>
        <div class="">
          <div class="">
            <b-button
              variant="flat-primary"
              class="btn-wishlist"
              block
              @click="move('login')"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>既にアカウントをお持ちの方はこちらから。</span>
            </b-button>
          </div>
        </div>
        <div class="text-center errorMSG">
          {{ errorMSG }}
        </div>
        <b-alert
          v-if="msg"
          variant="danger"
          show
        >
          <div class="alert-body mt-1 mb-2">
            {{ msg }}
          </div>
        </b-alert>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard,
  BCardText, BSpinner, BAlert, BAvatar,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import sendVerifyEmail from '@/firestore/authentication/sendMail'

export default {
  components: {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BAlert,
    BAvatar,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      mailaddress: '',
      password: '',
      // status: '',
      // validation rules
      required,
      email,
      status: 1,
      userType: '',
      errorMSG: '',
      msg: null,
    }
  },
  computed: {
    ...mapGetters('verifyEmail', ['verifyEmailStatus']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
  },
  methods: {
    async send() {
      this.status = 2
      const data = {
        mailaddress: this.mailaddress,
      }
      const response = await sendVerifyEmail(data)
      if (response.status === 'success') {
        this.$store.dispatch('verifyEmail/updateVerifyEmailStatus', 2)
        this.$store.dispatch('verifyEmail/updateMailaddress', this.mailaddress)
      } else {
        this.status = 1
        this.errorMSG = response.msg
      }
    },
    move(val) {
      this.$router.push({ name: val })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
.spinner_ {
  margin: 3% auto;
}
.errorMSG {
  font-size: 0.8rem;
  color:red
}
.aleart-text {
  font-size: 1rem;
}
</style>
