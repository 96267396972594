import firebase from 'firebase/app'

export default class CreateAccount {
  constructor() {
    this.label = ''
  }

  async create(payload) {
    const { mailaddress, password } = payload

    try {
      await firebase.auth().createUserWithEmailAndPassword(mailaddress, password)

      const response = {
        status: 'success',
      }
      return response
    } catch (error) {
      let errorMSG = ''
      switch (error.message) {
        case 'The email address is already in use by another account.':
          errorMSG = 'こちらのメールアドレスは既に使用されているため、\n\nユーザ登録ができませんでした。'
          break
        case 'Password should be at least 6 characters':
          errorMSG = 'パスワードは6文字以上必要です。'
          break
        default:
          errorMSG = error.message
          break
      }
      const response = {
        status: 'error',
        msg: errorMSG,
      }
      return response
    }
  }
}
