import CreateUser from '@/firestore/authentication/CreateUser'
import ResisterUser from '@/firestore/authentication/ResisterUser'
import SendMail from '@/firestore/mail/SendMail'

// アカウントの作成
// ①メアドとパスワードからFirebaseへ登録
// ②{user}に反映
// ③アカウント作成完了のメールを送る
export default class CreateMain {
  constructor() {
    this.msg = ''
  }

  async execute(payload) {
    const data = payload
    // const { mailaddress, password, primaryUserType } = payload

    // ①
    const user = new CreateUser()
    const res = await user.create(data)
    if (res.status === 'error') return { status: 'error', msg: res.msg }

    // ②
    delete data.password
    const resisterUser = new ResisterUser()
    const re = await resisterUser.register(data)
    if (re.status === 'error') return { status: 'error', msg: re.msg }

    // ③
    const sendMail = new SendMail()
    data.msgID = 2
    data.category = 'registerUserInfo_notification'

    const resp = await sendMail.send(data)
    if (resp.status === 'error') return { status: 'error', msg: resp.msg }

    return { status: 'success' }
  }
}
