<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <h2 class="text-center text-primary">
          タッチファミリーケア
        </h2>
        <div class="text-center text-primary mb-1">
          ユーザ登録
        </div>

        <b-card-title class="text-center mb-2">
          <!-- ようこそ、<br>抗体価検査サービスへ！！ -->
        </b-card-title>
        <b-card-text class="text-center mb-2">
          <div class="">
            下記のアドレスにメールを送信しました。
          </div>
          <div class="mailaddresText_ text-center text-primary">
            {{ mailaddress }}
          </div>
        </b-card-text>
        <b-card-text class="mb-2">
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body mt-1 mb-2">
              <div class="d-flex">
                <div class="">
                  <b-avatar
                    size="40"
                    src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
                    variant="light-primary"
                  />
                </div>
                <div class="ml-1">
                  メールアドレスが有効か確認します。
                </div>
              </div>
            </div>
          </b-alert>
        </b-card-text>
        <b-card-text>
          <div class="">
            メールに記載されおります４桁の確認番号を入力して｢確認する｣ボタンを押して下さい。
          </div>
        </b-card-text>
        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
          >

            <!-- email -->
            <b-form-group
              label-for="verifyNumber"
              label="確認番号"
            >
              <validation-provider
                name="確認番号"
              >
                <b-form-input
                  id="verifyNumber"
                  v-model="verifyNumber"
                  class="input_"
                  name="login-email"
                  placeholder="1234"
                  autofocus
                  type="number"
                />
                <small class="text-danger">{{ errorMSG }}</small>
              </validation-provider>
            </b-form-group>
            <b-alert
              variant="danger"
              show
            >
              <div class="alert-body mt-1 mb-2">
                【ご注意下さい】
                ブラウザをこのまま閉じずに、確認番号を入力してください。
                一旦ブラウザを閉じたり、通信が途切れた場合、パスワード設定が正常にできませんのでご注意下さい。
              </div>
            </b-alert>
            <!-- submit button -->
            <b-button
              v-if="status == 1"
              variant="primary"
              block
              :disabled="invalid"
              style="margin: 10% 0;"
              @click="confirm"
            >
              確認する
            </b-button>
            <div
              v-if="status == 2"
              class="text-center mt-2"
            >
              <b-spinner
                style="width: 3rem; height: 3rem;"
                class="spinner_"
                variant="primary"
              />
            </div>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BCardTitle,
  BCardText, BSpinner, BAlert, BAvatar,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import verifyNumbers from '@/firestore/authentication/verifyNumber'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    // BLink,
    // VuexyLogo,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BAlert,
    BAvatar,
  },
  data() {
    return {
      errorMSG: '',
      required,
      email,
      status: 1,
      verifyNumber: null,
    }
  },
  computed: {
    ...mapGetters('verifyEmail', ['mailaddress']),
  },
  mounted() {
  },
  methods: {
    async confirm() {
      this.status = 2
      const data = {
        mailaddress: this.mailaddress,
        verifyNumber: this.verifyNumber,
      }
      const response = await verifyNumbers(data)
      if (response.status === 'success') {
        this.$store.dispatch('verifyEmail/updateVerifyEmailStatus', 3)
      } else {
        this.errorMSG = response.msg
        this.status = 1
        // window.console.log('error', response.msg)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<style scoped>
.spinner_ {
  margin: 3% auto;
}
.mailaddresText_ {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 10% 0;
}
.input_ {
  font-size: 1.4rem;
  letter-spacing: 10px;
}
</style>
