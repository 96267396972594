<template>
  <div>
    <MailForm v-if="verifyEmailStatus == 1" />
    <VerifyEmail v-if="verifyEmailStatus == 2" />
    <CreateAccount v-if="verifyEmailStatus == 3" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MailForm from '@/components/authentication/MailForm.vue'
import VerifyEmail from '@/components/authentication/VerifyEmail.vue'
import CreateAccount from '@/components/authentication/CreateAccount.vue'

export default {
  components: {
    MailForm,
    VerifyEmail,
    CreateAccount,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('verifyEmail', ['verifyEmailStatus']),
  },
  mounted() {
    this.$store.dispatch('verifyEmail/updateVerifyEmailStatus', 1)
    this.$store.dispatch('userBasic/resetAll')
  },
}
</script>

<style lang="scss">
</style>
