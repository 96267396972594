const bar = [
  {
    id: 11,
    title: '【タッチファミリーケア】プラットフォームへユーザ登録をしましょう',
    text: 'お客様\n\nお世話になっております、\nタッチファミリーケアです。\n\nこの度はタッチファミリーケアにお申込みくださいまして、\n誠にありがとうございます。\n\n以下の確認番号をご入力下さい。\n------------\n確認番号：【   VERIFY_NUMBER   】\n------------\n※この番号を使ってメールアドレスの有効性を\n確認しています。\n\n引き続きよろしくお願いいたします。\n\nタッチファミリーケア',
  },
  {
    id: 2,
    title: '【タッチファミリーケア】アカウント作成が完了しました',
    text: 'お客様\n\nお世話になっております、タッチファミリーケアです。\n\n下記の通り、アカウント作成が完了しました。\n\n▽ご登録内容\nメールアドレス：MAIL_ADDRESS\nパスワード：(セキュリティのため非表示)\n\n引き続きよろしくお願いいたします。\n\nタッチファミリーケア',
  },
]

export default bar
