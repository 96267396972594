import firebase from 'firebase/app'
import store from '@/store'

// 初期登録：ここではユーザタイプ(userTypeList)は登録しない。
export default class ResisterUser {
  constructor() {
    this.label = ''
  }

  async register(payload) {
    const { mailaddress, primaryUserType } = payload

    try {
      const user = await firebase.auth().currentUser

      store.dispatch('userBasic/updateUid', user.uid)
      store.dispatch('userBasic/updateMail', user.email)

      const d = {
        docID: user.uid,
        insertTime: new Date(),
        userType: primaryUserType,
        mail: mailaddress,
        uid: user.uid,
        isUserProfile: false,
        loginHistory: [{ loginDate: new Date() }],
      }

      await firebase.firestore().collection('user').doc(user.uid).set(d, { merge: true })

      const response = {
        status: 'success',
      }
      return response
    } catch (error) {
      window.console.log('error', error)
      const response = {
        status: 'error',
        msg: 'ユーザ登録が正常に登録されませんでした。',
      }
      return response
    }
  }
}
