import firebase from 'firebase/app'
import mail from '@/firestore/mail/msg'

export default class SendMail {
  constructor() {
    this.label = ''
  }

  async send(payload) {
    const { mailaddress, msgID, category } = payload

    try {
      const msg = mail.find(v => v.id === msgID)
      let text = String(msg.text)
      text = String(text).replace(/MAIL_ADDRESS/g, mailaddress)

      const mailTo = [
        'tfc_info@touch-f-care.com',
        'chikako@be-creation.jp',
      ]
      mailTo.push(mailaddress)

      const content = {
        bcc: mailTo,
        message: {
          subject: msg.title,
          text,
        },
        category,
      }

      await firebase.firestore().collection('mail').add(content)
      const response = {
        status: 'success',
      }
      return response
    } catch (error) {
      const response = {
        status: 'error',
        msg: 'ユーザ登録完了のメールが配信されませんでした。',
      }
      return response
    }
  }
}
