<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <h2 class="text-center text-primary">
          タッチファミリーケア
        </h2>
        <div class="text-center text-primary mb-1">
          ユーザ登録
        </div>

        <b-card-title class="text-center mb-2">
          <!-- ようこそ、<br>抗体価検査サービスへ！！ -->
        </b-card-title>
        <b-card-text class="mb-2">
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body mt-1 mb-2">
              <div class="d-flex">
                <div class="">
                  <b-avatar
                    size="40"
                    src="https://firebasestorage.googleapis.com/v0/b/protex-b9e4c.appspot.com/o/brand%2Fwoman-intro.png?alt=media&token=998c4413-ebc2-46cc-a62d-16c0565dfe32"
                    variant="light-success"
                  />
                </div>
                <div class="ml-1">
                  ありがとうございます！<br>メールアドレスが確認できました。
                </div>
              </div>
            </div>
          </b-alert>
        </b-card-text>
        <b-card-text class="mb-2">
          <div class="">
            パスワードを入力して｢登録する｣ボタンを押して下さい。パスワードは大切に保管してください。
          </div>
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
          >

            <!-- email -->
            <b-form-group
              label-for="mail"
              label="Mail"
            >
              <validation-provider
                name="Mail"
              >
                <b-form-input
                  id="mail"
                  v-model="mailaddress"
                  name="login-email"
                  placeholder="1234"
                  disabled
                />
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <!-- <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge input_"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              v-if="status == 1 && !errorMSG"
              variant="primary"
              block
              :disabled="invalid"
              class="mt-3 mb-2"
              @click="register"
            >
              登録する
            </b-button>
            <div
              v-if="status == 2"
              class="text-center mt-2"
            >
              <b-spinner
                style="width: 3rem; height: 3rem;"
                class="spinner_"
                variant="primary"
              />
            </div>
            <b-alert
              v-if="errorMSG"
              variant="danger"
              show
            >
              <div class="alert-body mt-3 mb-2">
                {{ errorMSG }}
              </div>
            </b-alert>
            <div>
              <b-button
                variant="outline-primary"
                class="btn-wishlist"
                block
                @click="reset"
              >
                <feather-icon
                  icon="ChevronsLeftIcon"
                  class="mr-50"
                />
                <span>戻る</span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BCardTitle, BCardText,
  BInputGroup, BInputGroupAppend, BSpinner, BAlert, BAvatar,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import CreateMain from '@/components/authentication/js/CreateMain'
import LoginHistory from '@/firestore/authentication/LoginHistory'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    // BLink,
    // VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    // BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BAlert,
    BAvatar,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      errorMSG: null,
      required,
      email,
      status: 1,
      password: '',
    }
  },
  computed: {
    ...mapGetters('verifyEmail', ['mailaddress']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
  },
  methods: {
    async register() {
      this.status = 2
      const data = {
        mailaddress: this.mailaddress,
        password: this.password,
        primaryUserType: this.$route.query.type,
      }
      const createMain = new CreateMain()
      const response = await createMain.execute(data)
      if (response.status === 'success') {
        const history = new LoginHistory()
        await history.update()
        this.$store.dispatch('userBasic/updateIsLogin', true)
        this.$router.replace(getHomeRouteForLoggedInUser(this.$route.query.type))
      } else {
        this.errorMSG = response.msg
        this.status = 3
        window.console.log('error', response.msg)
      }
    },
    reset() {
      this.$store.dispatch('verifyEmail/updateVerifyEmailStatus', 1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
<style scoped>
.spinner_ {
  margin: 3% auto;
}
.input_ {
  font-size: 1.4rem;
  letter-spacing: 3px;
}
</style>
