import GetUserCollection from '@/firestore/data/GetUserCollection'
import UpdateSingleData from '@/firestore/data/UpdateSingleData'

export default class LoginHistory {
  constructor() {
    this.dic = {}
  }

  async update() {
    try {
      const user = new GetUserCollection()
      const res = await user.fetch()

      if (res.status === 'error ') return { status: 'error', msg: res.msg }

      const { loginHistory } = res.data

      if (loginHistory.length >= 10) loginHistory.shift()
      loginHistory.push({ loginDate: new Date() })

      const singleData = new UpdateSingleData()
      const resp = await singleData.update({ collection: 'user', data: res.data })
      if (resp.status === 'error ') return { status: 'error', msg: resp.msg }

      const response = {
        status: 'success',
      }
      return response
    } catch (error) {
      window.console.log('error', error)
      const response = {
        status: 'error',
        msg: error,
      }
      return response
    }
  }
}
